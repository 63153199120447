import {Box} from "@mui/material";

type profilePicData = {
    image: string;
    username: string;
}

type noProfilePicData = {
    username: string;
}

const ProfilePicture = (props: profilePicData) => {
    return (
        <Box>
            {props.image === null || props.image === "" ?
                <NoProfilePic username={props.username}/> :
                <img src={props.image} alt="Change Profile" style={{
                    width: "222px",
                    height: "222px",
                    borderRadius: "50%",
                    marginTop: "20px",
                    marginLeft: "20px",
                    marginRight: "20px",
                    marginBottom: "20px"
                }}/>
            }
        </Box>
    )
}

const NoProfilePic = (props: noProfilePicData) => {
    return (
        <>
            <div id="profileImage" style={{
                width: "222px",
                height: "222px",
                borderRadius: "50%",
                background: "#484848",
                fontSize: "50px",
                color: "white",
                textAlign: "center",
                lineHeight: "222px",
                marginTop: "20px",
                marginLeft: "20px",
                marginRight: "20px",
                marginBottom: "20px",
            }}>
                {props.username.toUpperCase().slice(0, 2)}
            </div>
        </>
    )
}

export default ProfilePicture;