import useSWR from "swr";
import {url} from "../../config";
import {deleteRequest, get, post} from "../../Requests/requests";
import {useState} from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogTitle, IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead, TableRow, Tooltip
} from "@mui/material";
import Icon from "@mdi/react";
import {mdiClipboardText, mdiDelete, mdiPlus} from "@mdi/js";

type keyData = {
    key: string;
    user: string;
}

export default function ApiKeys() {
    const queryParams = new URLSearchParams(window.location.search);
    const {
        data,
        mutate
    } = useSWR(url + `/api/v1/user/bucket/keys?name=${queryParams.get("name")}`, get)

    const [showDelete, setShowDelete] = useState<boolean>(false)
    const [deleteKey, setDeleteKey] = useState("")

    if (data) {
        return (
            <>
                <Dialog
                    open={showDelete}
                    onClose={() => setShowDelete(false)}>
                    <DialogTitle> Delete API Key? </DialogTitle>
                    <DialogActions>
                        <Button onClick={() => {
                            deleteRequest(url + "/api/v1/user/bucket/keys/delete", {
                                bucketname: queryParams.get("name"),
                                key: deleteKey
                            }, () => mutate())
                            setShowDelete(false)
                        }}>
                            Yes
                        </Button>
                        <Button onClick={() => setShowDelete(false)}> No </Button>
                    </DialogActions>
                </Dialog>
                <Button variant="contained" onClick={() => {
                    post(url + "/api/v1/user/bucket/keys/create", {
                        bucketname: queryParams.get("name")
                    }, () => mutate())
                }} sx={{float: "right"}}>
                    <Icon path={mdiPlus} size={1}/>
                    Create API Key
                </Button>
                <TableContainer sx={{maxWidth: "100%"}}>
                    <Table>
                        <TableHead>
                            <TableCell> Key </TableCell>
                            <TableCell> User </TableCell>
                            <TableCell> Controls </TableCell>
                        </TableHead>
                        <TableBody>
                            {data.data.map((key: keyData) => (
                                <TableRow key={key.key}>
                                    <TableCell>
                                        {key.key}
                                        <Tooltip title="Copy">
                                            <IconButton onClick={() => navigator.clipboard.writeText(key.key)}>
                                                <Icon path={mdiClipboardText} size={1}/>
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell>{key.user}</TableCell>
                                    <TableCell>
                                        <Tooltip title="Delete">
                                            <IconButton onClick={() => {
                                                setDeleteKey(key.key)
                                                setShowDelete(true)
                                            }}>
                                                <Icon path={mdiDelete} size={1}/>
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </>
        )
    }
}