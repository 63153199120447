import {LineChart} from "@mui/x-charts";

type propsData = {
    data: number[];
    date: any[];
    label: string;
    dataFormatter?: (value: number) => string;
    yAxisFormatter?: (value: number) => string;
}

export default function Chart(props: propsData) {
    return (
        <LineChart
            xAxis={[{
                data: props.date,
                scaleType: 'band',
            }]}
            series={[{
                label: props.label,
                data: props.data,
                valueFormatter: props.dataFormatter === null ? () => "" : props.dataFormatter,
            }]}
            yAxis={[{
                valueFormatter: props.yAxisFormatter === null ? () => "" : props.yAxisFormatter
            }]}
            width={600}
            height={300}
        />
    )
}