import {Box, Button, Divider, TextField, Typography} from "@mui/material";
import useSWR from "swr";
import {url} from "../../config"
import ProfilePicture from "./ProfilePicture";
import Icon from "@mdi/react";
import {mdiPencil} from "@mdi/js";
import {useState} from "react";
import {get, post} from "../../Requests/requests";

const Settings = () => {
    const [changeUsernameError, setChangeUsernameError] = useState(false)
    const [changePasswordError, setChangePasswordError] = useState(false)
    const [changePasswordNewPasswordError, setChangePasswordNewPasswordError] = useState(false)
    const {
        data,
        mutate
    } = useSWR(url + "/api/v1/user/profile", get)

    if (data) {
        return (
            <Box display="inline-flex">
                <Box>
                    <ProfilePicture image={data.picture} username={data.username}/>
                    <Button
                        style={{marginRight: "8px", alignSelf: "start", marginLeft: "20px"}}
                        component="label"
                        variant="contained"
                        startIcon={<Icon path={mdiPencil} size={1.2}/>}
                        size="small"
                    >
                        <input id="change-profile-picture-selector" type="file" hidden={true}
                               onChange={() => {
                                   const fileSelector = document.getElementById("change-profile-picture-selector") as HTMLInputElement
                                   let file: File
                                   file = null

                                   if (fileSelector.files && fileSelector.files[0]) {
                                       file = fileSelector.files[0]
                                       if (file.size / 1024 / 1024 > 10) return
                                   }

                                   if (file === null)
                                       return

                                   const formdata = new FormData()
                                   formdata.append("file", file)

                                   post(url + "/api/v1/user/settings/picture/change", formdata, () => mutate())
                               }} accept=".png, .jpg. jpeg"/>
                        Change Profile Picture
                    </Button>
                </Box>
                <Box display="flex" flexDirection="column" marginTop="10px">
                    <Typography fontSize="24px" fontWeight="bold"> Change Username </Typography>
                    <TextField placeholder={data.username} style={{marginTop: "5px"}} id="change-username-input"
                               variant="outlined" error={changeUsernameError}/>
                    <Button variant="contained" style={{marginTop: "10px"}} onClick={() => {
                        post(url + "/api/v1/user/settings/username/change", {
                            username: (document.getElementById("change-username-input") as HTMLInputElement).value
                        }, (response) => {
                            if (response.status === 200) {
                                setChangeUsernameError(false);
                                (document.getElementById("change-username-input") as HTMLInputElement).value = ""
                                mutate()
                            } else {
                                setChangeUsernameError(true)
                            }
                        })
                    }}
                            color={changeUsernameError ? "error" : "primary"}> Change
                        Username </Button>

                    <Divider orientation="horizontal" style={{marginTop: "15px"}}/>

                    <Typography fontSize="24px" fontWeight="bold" style={{marginTop: "8px"}}> Change
                        Password </Typography>
                    <TextField placeholder="Current Password" style={{marginTop: "8px"}} variant="outlined"
                               type="password" id="change-password-input" error={changePasswordError}/>
                    <TextField placeholder="New Password" style={{marginTop: "8px"}} variant="outlined" type="password"
                               id="change-password-input-new"
                               error={changePasswordError || changePasswordNewPasswordError}/>
                    <TextField placeholder="Repeat New Password" style={{marginTop: "8px"}} variant="outlined"
                               type="password" id="change-password-input-new-repeat"
                               error={changePasswordError || changePasswordNewPasswordError}/>
                    <Button variant="contained" style={{marginTop: "10px"}}
                            color={changePasswordError || changePasswordNewPasswordError ? "error" : "primary"}
                            onClick={() => {
                                const currentPassword = (document.getElementById("change-password-input") as HTMLInputElement).value
                                const newPassword = (document.getElementById("change-password-input-new") as HTMLInputElement).value
                                const newPasswordRepeat = (document.getElementById("change-password-input-new-repeat") as HTMLInputElement).value

                                if (newPassword !== newPasswordRepeat) {
                                    setChangePasswordNewPasswordError(true)
                                    return
                                }

                                post(url + "/api/v1/user/settings/password/change", {
                                    password: currentPassword,
                                    newpassword: newPassword
                                }, (response) => {
                                    if (response.status === 200) {
                                        localStorage.setItem("token", response.data.token)
                                        setChangePasswordError(false)
                                        setChangePasswordNewPasswordError(false)
                                    } else {
                                        setChangePasswordError(true)
                                    }
                                })
                            }}> Change Password </Button>
                </Box>
            </Box>
        )
    }
}

export default Settings;