import {useSearchParams} from "react-router-dom";
import useSWR from "swr";
import {url} from "../../config";
import {get, post} from "../../Requests/requests";
import {useEffect, useState} from "react";
import {
    Button,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Tooltip
} from "@mui/material";
import Icon from "@mdi/react";
import {mdiDelete, mdiPlus} from "@mdi/js";

export default function EditBucket() {
    const queryParams = new URLSearchParams(window.location.search);
    // eslint-disable-next-line
    const [searchParams, setSearchParams] = useSearchParams()
    const {
        data,
        mutate
    } = useSWR(url + `/api/v1/user/bucket/user/allowed?name=${queryParams.get("name")}`, get)
    const [allowedUsers, setAllowedUsers] = useState<string[]>([])
    useEffect(() => {
        if (data) {
            setAllowedUsers(data.data.map((user: { name: string }) => user.name))
        }
    }, [data])

    function checkForUser(user: string): boolean {
        return allowedUsers.includes(user)
    }

    function addUser() {
        const inputElement = document.getElementById("new-allowed-user-input") as HTMLInputElement
        const user = inputElement.value
        if (checkForUser(user)) {
            inputElement.value = ""
            return
        }
        const updatedUsers = allowedUsers.map(x => x)
        updatedUsers.push(user)
        setAllowedUsers(updatedUsers)
        inputElement.value = ""
    }

    function bucketNameChanged(name: string) {
        setSearchParams(params => {
            params.set("name", name)
            return params
        })
    }

    const handleRemoveAllowed = (itemToRemove: string) => {
        const updatedItems = allowedUsers.filter((item: string) => item !== itemToRemove);
        setAllowedUsers(updatedItems);
    };

    if (data) {
        return (
            <div style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column"
            }}>
                <TextField
                    label="New Bucket Name"
                    size="medium"
                    sx={{
                        maxWidth: "60%"
                    }}
                    fullWidth
                    id="edit-bucket-name"
                />
                <TextField
                    label="New Owner Name"
                    size="medium"
                    sx={{
                        maxWidth: "60%",
                        marginTop: "10px"
                    }}
                    fullWidth
                    id="edit-bucket-owner"
                />
                <TableContainer sx={{
                    marginTop: "30px",
                    maxWidth: "60%"
                }}>
                    <TextField
                        id="new-allowed-user-input"
                        label="Add User"
                        InputProps={{
                            endAdornment: (
                                <Tooltip title="Add User">
                                    <IconButton onClick={() => addUser()}>
                                        <Icon path={mdiPlus} size={1}/>
                                    </IconButton>
                                </Tooltip>
                            ),
                        }}
                        variant="standard"
                    />
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell> User </TableCell>
                                <TableCell> <span style={{float: "right"}}> Controls </span> </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {allowedUsers.map((name) => (
                                <TableRow
                                    key={name}
                                >
                                    <TableCell className="allowed-cell">{name}</TableCell>
                                    <TableCell>
                                        <Tooltip title="Delete" sx={{float: "right"}}>
                                            <IconButton size="medium" onClick={() => handleRemoveAllowed(name)}>
                                                <Icon path={mdiDelete} size={1}/>
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Button
                    variant="contained"
                    sx={{
                        marginTop: "10px",
                        maxWidth: "30%"
                    }}
                    size="large"
                    fullWidth
                    onClick={() => {
                        const newBucketName = (document.getElementById("edit-bucket-name") as HTMLInputElement).value
                        post(url + "/api/v1/user/bucket/edit", {
                            newowner: (document.getElementById("edit-bucket-owner") as HTMLInputElement).value,
                            bucketname: queryParams.get("name"),
                            newbucketname: newBucketName,
                            userlist: Array.from(document.getElementsByClassName("allowed-cell")).map(e => e.innerHTML)
                        }, () => {
                            if(newBucketName !== null && newBucketName !== "")
                                bucketNameChanged(newBucketName)
                            mutate()
                        })
                    }}
                >
                    Submit
                </Button>
            </div>
        )
    }
}