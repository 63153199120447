import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@mui/material";
import {post} from "../../Requests/requests";
import {url} from "../../config"
import {KeyedMutator} from "swr";

type propsData = {
    createDialog: boolean;
    setCreateDialog: (value: boolean) => void;
    mutate: KeyedMutator<any>;
}

export default function CreateBucketDialog(props: propsData) {
    return (
        <Dialog
            open={props.createDialog}
            onClose={() => props.setCreateDialog(false)}
            maxWidth="xs"
            fullWidth
        >
            <DialogTitle> Create Bucket </DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    label="Bucket Name"
                    variant="standard"
                    type="text"
                    fullWidth
                    id="name"
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {
                    const bucketName = document.getElementById("name") as HTMLInputElement
                    post(url + "/api/v1/user/bucket/create", {name: bucketName.value}, () => props.mutate())
                    props.setCreateDialog(false)
                }}> Create </Button>
                <Button onClick={() => props.setCreateDialog(false)}> Cancel </Button>
            </DialogActions>
        </Dialog>
    )
}