import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import {deleteRequest} from "../../Requests/requests";
import {url} from "../../config";
import {KeyedMutator} from "swr";

type propsData = {
    deleteConfirm: boolean;
    setDeleteConfirm: (value: boolean) => void;
    deleteFileName: string;
    searchParams: URLSearchParams;
    mutate: KeyedMutator<any>;
    type: string;
}

export default function DeleteFileDialog(props: propsData) {
    return (
        <Dialog
            open={props.deleteConfirm}
            onClose={() => props.setDeleteConfirm(false)}
        >
            <DialogTitle> Delete {props.type.slice(0, 1).toUpperCase() + props.type.slice(1)} </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you sure you want to delete [{props.deleteFileName}]?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {
                    deleteRequest(url + "/api/v1/user/bucket/file/delete", {
                        bucketname: props.searchParams.get("name"),
                        path: props.searchParams.get("path"),
                        filename: props.deleteFileName
                    }, () => {
                        props.mutate()
                        props.setDeleteConfirm(false)
                    })
                }}>
                    Delete
                </Button>
                <Button onClick={() => props.setDeleteConfirm(false)}> Cancel </Button>
            </DialogActions>
        </Dialog>
    )
}