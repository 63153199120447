import {AppBar, IconButton, Menu, MenuItem, Toolbar, Typography} from "@mui/material";
import Logo from "../img/logo.png"
import * as React from "react";
import {useState} from "react";
import useSWR from "swr";
import {url} from "../config"
import {get} from "../Requests/requests";

function signOut() {
    localStorage.removeItem("token")
    window.location.assign("/login")
}

const Navbar = (props: { loggedIn: boolean; }) => {
    const [anchorEl, setAnchorEl] = useState<Element>()
    const [isHover, setIsHover] = useState<boolean>(false)
    const {data} = useSWR(url + "/api/v1/user/profile", get)
    const handleMenu = (event: any) => {
        setAnchorEl(event["target"])
    }
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMouseEnter = () => {
        setIsHover(true);
    };
    const handleMouseLeave = () => {
        setIsHover(false);
    };


    return (
        <AppBar position="absolute">
            <Toolbar>
                <img src={Logo} style={{
                    width: "48px",
                    height: "48px",
                    marginBottom: "13px"
                }}
                     alt=""/>
                <Typography variant="h6" component="span" sx={{flexGrow: 1, marginLeft: "6px", fontWeight: "bold"}}>
                    Skriblz S3
                </Typography>
                {props.loggedIn && (
                    <div>
                        <Typography variant="subtitle2" component="span" sx={{flexGrow: 1, marginRight: "6px"}}>
                        <span
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                        >
                            {isHover ?
                                <span style={{filter: "brightness(0.4)", cursor: "pointer"}}
                                      onClick={() => window.location.assign("/")}> Buckets </span> :
                                <span> Buckets </span>}
                        </span>
                        </Typography>
                        <IconButton
                            size="small"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleMenu}
                            color="inherit"
                            edge="end"
                        >
                            {data ? (
                                <>
                                    {data.picture === null || data.picture === "" ? (
                                        <div id="profileImage" style={{
                                            width: "30px",
                                            height: "30px",
                                            borderRadius: "50%",
                                            background: "#484848",
                                            fontSize: "14px",
                                            color: "white",
                                            textAlign: "center",
                                            lineHeight: "30px",
                                        }}>
                                            {data.username.toUpperCase().slice(0, 2)}
                                        </div>
                                    ): (
                                        <img src={data.picture} alt="Change Profile" style={{
                                            width: "30px",
                                            height: "30px",
                                            borderRadius: "50%",
                                        }}/>
                                    )}
                                </>
                            ) : null}
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            /* @ts-ignore */
                            setAnchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            <MenuItem onClick={() => window.location.assign("/settings")}> Settings </MenuItem>
                            <MenuItem onClick={signOut}> Sign Out </MenuItem>
                        </Menu>
                    </div>
                )}
            </Toolbar>
        </AppBar>
    )
}

export default Navbar;