import useSWR from "swr";
import {
    Button,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip
} from "@mui/material";
import Icon from "@mdi/react";
import {mdiDelete, mdiPlus} from "@mdi/js";
import {useEffect, useState} from "react";
import {url} from "../../config"
import {get} from "../../Requests/requests";
import DeleteConfirmDialog from "./DeleteConfirmDialog";
import CreateBucketDialog from "./CreateBucketDialog";

type bucketData = {
        name: string;
        createdOnTime: string;
        createdOnDate: string;
        owner: string;
        role: string;
}

const BucketTable = () => {
    const [bucketName, setBucketName] = useState<string>("")
    const {data, isLoading, mutate} = useSWR(url + "/api/v1/user/bucket/all", get)
    const [deleteConfirm, setDeleteConfirm] = useState<boolean>(false)
    const [createDialog, setCreateDialog] = useState<boolean>(false)

    const showDeleteConfirm = (bucketName: string) => {
        setBucketName(bucketName)
        setDeleteConfirm(true)
    }

    if (isLoading) {
        return (
            <div>
                <p> Loading </p>
            </div>
        )
    }
    if (data) {
        return (
            <>
                <DeleteConfirmDialog deleteConfirm={deleteConfirm} setDeleteConfirm={setDeleteConfirm} bucketName={bucketName} mutate={mutate}/>
                <CreateBucketDialog createDialog={createDialog} setCreateDialog={setCreateDialog} mutate={mutate}/>
                <Button
                    variant="contained"
                    size="small"
                    sx={{float: "right", marginRight: "8px", marginTop: "10px"}}
                    onClick={() => setCreateDialog(true)}
                >
                    <Icon path={mdiPlus} size={1}/>
                    Create Bucket
                </Button>

                <TableContainer>
                    <Table sx={{width: "100%"}}>
                        <TableHead>
                            <TableRow>
                                <TableCell component="th" sx={{fontWeight: "bold"}}> Bucket </TableCell>
                                <TableCell component="th" sx={{fontWeight: "bold"}}> Role </TableCell>
                                <TableCell component="th" sx={{fontWeight: "bold"}}> Created </TableCell>
                                <TableCell component="th" sx={{fontWeight: "bold"}}> Owner </TableCell>
                                <TableCell component="th" sx={{fontWeight: "bold"}}> Controls </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.data.map((bucket: bucketData) => (
                                <TableRow
                                    key={bucket.name}
                                    sx={{
                                        "& th": {
                                            height: "50px",
                                            textAlign: "start",
                                        },
                                        "& th:first-child span:hover": {
                                            filter: "brightness(0.4)",
                                            cursor: "pointer"
                                        }
                                    }}>
                                    <TableCell component="th" scope="row" size="small">
                                    <span
                                        onClick={() => window.location.assign(`/view?name=${bucket.name}&path=`)}>{bucket.name}</span>
                                    </TableCell>
                                    <TableCell component="th" scope="row" size="small">{bucket.role}</TableCell>
                                    <TableCell component="th" scope="row" size="small">
                                        <span> {bucket.createdOnTime}, {bucket.createdOnDate} </span>
                                    </TableCell>
                                    <TableCell component="th" scope="row" size="small">{bucket.owner}</TableCell>
                                    <TableCell component="th" scope="row" size="small">
                                        <Tooltip title="Delete">
                                            <IconButton onClick={() => showDeleteConfirm(bucket.name)}>
                                                <Icon path={mdiDelete} size={1}/>
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </>
        )
    }
}

const Overview = () => {
    useEffect(() => {
        document.title = 'Bucket Overview';
    }, [])
    return (
        <div className="content">
            <div>
                <BucketTable/>
            </div>
        </div>
    );
}

export default Overview;