import './App.css';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import {createTheme, ThemeProvider} from "@mui/material";
import CssBaseline from '@mui/material/CssBaseline';
import Login from "./Pages/Login.tsx";
import Overview from "./Pages/Overview/Overview";
import FileView from "./Pages/FileView/FileView";
import Navbar from "./Pages/Navbar";
import Settings from "./Pages/Settings/Settings";
import Admin from "./Pages/Admin/Admin";

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
    },
});


function App() {
    if(!localStorage.getItem("token") && !window.location.href.includes("/login"))
        window.location.assign("/login")

    return (
        <ThemeProvider theme={darkTheme}>
            <CssBaseline/>
            <Navbar loggedIn={!!localStorage.getItem("token")}/>
            <div style={{
                width: "100%",
                height: "fit-content",
                marginTop: "64px"
            }}>
                <Router>
                    <Routes>
                        <Route path='/' Component={Overview}/>
                        <Route path='/login' Component={Login}/>
                        <Route path='/view' Component={FileView}/>
                        <Route path='/settings' Component={Settings}/>
                        <Route path='/admin' Component={Admin}/>
                    </Routes>
                </Router>
            </div>
        </ThemeProvider>
    );
}

export default App;
