import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@mui/material";
import {post} from "../../Requests/requests";
import {url} from "../../config";
import {KeyedMutator} from "swr";

type dialogProps = {
    editFileName: boolean;
    oldFileName: string
    setEditFileName: (value: boolean) => void;
    searchParams: URLSearchParams;
    mutate: KeyedMutator<any>;
}

export default function EditFileDialog(props: dialogProps) {
    return (
        <Dialog
            open={props.editFileName}
            onClose={() => props.setEditFileName(false)}
            maxWidth="xs"
            fullWidth
        >
            <DialogTitle> Edit File </DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    label="New Name"
                    variant="standard"
                    type="text"
                    fullWidth
                    id="edit-file-name"
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {
                    post(url + "/api/v1/user/bucket/file/rename", {
                        bucketname: props.searchParams.get("name"),
                        path: props.searchParams.get("path"),
                        filename: props.oldFileName,
                        newfilename: (document.getElementById("edit-file-name") as HTMLInputElement).value,
                    }, () => {
                        props.mutate()
                        props.setEditFileName(false)
                    })
                }}>
                    Edit
                </Button>
                <Button onClick={() => props.setEditFileName(false)}> Cancel </Button>
            </DialogActions>
        </Dialog>
    )
}