import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import {deleteRequest} from "../../Requests/requests";
import {url} from "../../config"
import {KeyedMutator} from "swr";

type propsData = {
    deleteConfirm: boolean;
    setDeleteConfirm: (value: boolean) => void;
    bucketName: string;
    mutate: KeyedMutator<any>;
}

export default function DeleteConfirmDialog(props: propsData) {
    return (
        <Dialog
            open={props.deleteConfirm}
            onClose={() => props.setDeleteConfirm(false)}
        >
            <DialogTitle> Confirm Delete </DialogTitle>
            <DialogContent> Do You want to Delete [{props.bucketName}]? </DialogContent>
            <DialogActions>
                <Button onClick={() => {
                    props.setDeleteConfirm(false)
                    deleteRequest(url + "/api/v1/user/bucket/delete", {bucketname: props.bucketName}, () => props.mutate())
                }}> Yes </Button>
                <Button autoFocus onClick={() => props.setDeleteConfirm(false)}> No </Button>
            </DialogActions>
        </Dialog>
    )
}