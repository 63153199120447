import {Card, Grid} from "@mui/material";
import Chart from "./Chart";

const style = {
    marginLeft: "30px",
    marginRight: "30px"
}

interface IData {
    data: number[];
    date: string[];
    label: string;
    dataFormatter?: (value: number) => string;
    yAxisFormatter?: (value: number) => string;
}

const data: Array<IData> = [
    {
        data: [10, 5, 6, 5, 0, 18, 20],
        date: ["Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag", "Sontag"],
        label: "Registered"
    }, {
        data: [5000, 150000000, 100000, 11451, 4781871, 4174111, 549148972],
        date: ["Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag", "Sontag"],
        label: "Size",
        dataFormatter: convertByteToUnit,
        yAxisFormatter: convertByteToUnit
    }, {
        data: [8943, 123, 343, 123, 5435435, 233, 12314333],
        date: ["Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag", "Sontag"],
        label: "Uploaded Files",
    }
]

function convertByteToUnit(value: number) {
    const units = ['B', 'KB', 'MB', 'GB', 'TB'];
    let size = value;

    let unitIndex = 0;
    while (size >= 1024 && unitIndex < units.length - 1) {
        size /= 1024;
        unitIndex++;
    }

    return size.toString().slice(0, 2) + ' ' + units[unitIndex];
}

const width = 600
const height = 400

export default function Admin() {

    return (
        /*
        <Grid container spacing={2}>
            <Grid item width={width} height={height} style={style}>
                <Card>
                    <Chart data={[10, 5, 6, 5, 0, 18, 20]}
                           date={["Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag", "Sontag"]}
                           label="Registered"
                    />
                </Card>
            </Grid>
            <Grid item width={width} height={height} style={style}>
                <Card sx={{borderRadius: "20px"}}>
                    <Chart data={[5000, 150000000, 100000, 11451, 4781871, 4174111, 549148972]}
                           date={["Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag", "Sontag"]}
                           label="Size"
                           dataFormatter={convertByteToUnit}
                           yAxisFormatter={convertByteToUnit}
                    />
                </Card>
            </Grid>
        </Grid>
     */

    <Grid container spacing={2}>
        {data.map((data) => (
            <Grid item width={width} height={height} style={style}>
                <Card sx={{borderRadius: "20px"}}>
                    <Chart data={data.data}
                           date={data.date}
                           label={data.label}
                           dataFormatter={data.dataFormatter === null ? () => "" : data.dataFormatter}
                           yAxisFormatter={data.yAxisFormatter === null ? () => "" : data.yAxisFormatter}
                    />
                </Card>
            </Grid>
        ))}
    </Grid>
    )
}
