import {
    Button,
    FormControl,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    TextField,
    Typography
} from "@mui/material";
import Stack from '@mui/material/Stack';
import {useEffect, useState} from "react";
import Icon from "@mdi/react";
import {mdiEye, mdiEyeOff} from "@mdi/js";
import { url } from "../config"
import {post} from "../Requests/requests";

const Login = () => {
    useEffect(() => {
        document.title = 'Login / Register';
    }, [])
    const [loginError, setLoginError] = useState<boolean>(false)
    const [registerError, setRegisterError] = useState<boolean>(false)

    const [showPassword, setShowPassword] = useState(false);
    const [loginUsername, setLoginUsername] = useState("")
    const [loginPassword, setLoginPassword] = useState("")
    const [registerUsername, setRegisterUsername] = useState("")
    const [registerPassword, setRegisterPassword] = useState("")
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    return (
        <div style={{display: "flex"}}>
            <div style={{
                width: "50%",
            }}>
                <Stack
                    spacing={1.5}
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    height="85vh"
                >
                    <Typography component="h1" sx={{fontWeight: "bold", fontSize: "24px"}}> Login </Typography>
                    <TextField
                        label="Username"
                        sx={{minWidth: "350px"}}
                        onChange={(input) => setLoginUsername(input.target["value"])}
                        error={loginError}
                    />
                    <FormControl sx={{minWidth: "350px"}} variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-password">
                            Password
                        </InputLabel>
                        <OutlinedInput
                            label="Password"
                            id="outlined-adornment-password"
                            error={loginError}
                            type={showPassword ? "text" : "password"}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {showPassword ? <Icon path={mdiEye} size={1}/> :
                                            <Icon path={mdiEyeOff} size={1}/>}
                                    </IconButton>
                                </InputAdornment>
                            }
                            onChange={(input) => setLoginPassword(input.target["value"])}
                            value={loginPassword}
                        />
                    </FormControl>
                    <Button
                        variant="contained"
                        sx={{minWidth: "150px"}}
                        onClick={() => post(url + "/api/v1/user/login", {
                            username: loginUsername,
                            password: loginPassword
                        }, (response) => {
                            if(response.status === 400) {
                                setLoginError(true)
                                return
                            }

                            localStorage.setItem("token", response.data.token)
                            window.location.assign("/")
                        })}
                        color={loginError ? "error" : "primary"}
                    >
                        Log In
                    </Button>
                </Stack>
            </div>
            <div style={{
                width: "3px",
                height: "88vh",
                marginTop: "20px",
                backgroundColor: "#ffffff0a",
                borderRadius: "50px"
            }}/>

            <div style={{
                width: "50%",
            }}>
                <Stack
                    spacing={1.5}
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    height="85vh"
                >
                    <Typography component="h1" sx={{fontWeight: "bold", fontSize: "24px"}}> Register </Typography>
                    <TextField
                        label="Username"
                        sx={{minWidth: "350px"}}
                        onChange={(input) => setRegisterUsername(input.target["value"])}
                        error={registerError}
                    />
                    <FormControl sx={{minWidth: "350px"}} variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-password">
                            Password
                        </InputLabel>
                        <OutlinedInput
                            label="Password"
                            id="outlined-adornment-password"
                            sx={{minWidth: "350px", color: "white"}}
                            type={showPassword ? "text" : "password"}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {showPassword ? <Icon path={mdiEye} size={1}/> :
                                            <Icon path={mdiEyeOff} size={1}/>}
                                    </IconButton>
                                </InputAdornment>
                            }
                            error={registerError}
                            onChange={(input) => setRegisterPassword(input.target["value"])}
                            value={registerPassword}
                        />
                    </FormControl>
                    <Button
                        variant="contained"
                        sx={{minWidth: "150px"}}
                        onClick={() => post(url + "/api/v1/user/register", {
                            username: registerUsername,
                            password: registerPassword
                        }, (response) => {
                            if(response.status === 400) {
                                setRegisterError(true)
                                return
                            }

                            localStorage.setItem("token", response.data.token)
                            window.location.assign("/")
                        })}
                        color={registerError ? "error" : "primary"}
                    >
                        Register
                    </Button>
                </Stack>
            </div>
        </div>
    );
}

export default Login;