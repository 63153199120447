import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@mui/material";
import {post} from "../../Requests/requests";
import {url} from "../../config";
import {KeyedMutator} from "swr";

type propsData = {
    folderConfirm: boolean;
    setFolderConfirm: (value: boolean) => void;
    searchParams: URLSearchParams;
    mutate: KeyedMutator<any>;
}

export default function CreateFolderDialog(props: propsData) {
    return (
        <Dialog
            open={props.folderConfirm}
            onClose={() => props.setFolderConfirm(false)}
            maxWidth="xs"
            fullWidth
        >
            <DialogTitle> Create Folder </DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    label="Folder Name"
                    variant="standard"
                    type="text"
                    fullWidth
                    id="set-folder-name"
                />
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        post(url + "/api/v1/user/bucket/folder/create", {
                            bucketname: props.searchParams.get("name"),
                            path: props.searchParams.get("path"),
                            foldername: (document.getElementById("set-folder-name") as HTMLInputElement).value
                        }, () => {
                            props.mutate()
                            props.setFolderConfirm(false)
                        })
                    }}> Create </Button>
                <Button onClick={() => props.setFolderConfirm(false)}> Cancel </Button>
            </DialogActions>
        </Dialog>
    )
}