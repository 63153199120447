import useSWR from "swr";
import {useSearchParams} from "react-router-dom";
import {
    AppBar,
    Box,
    Button,
    IconButton,
    LinearProgress,
    LinearProgressProps,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tabs,
    Tooltip,
    Typography,
    useTheme
} from "@mui/material";
import SwipeableViews from 'react-swipeable-views';
import {mdiDelete, mdiDownload, mdiPencil, mdiPlus, mdiUpload} from "@mdi/js";
import Icon from "@mdi/react";
import {useEffect, useState} from "react";
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {url} from "../../config";
import {get, post} from "../../Requests/requests"
import TabPanel from "./TabPanel";
import ApiKeys from "./ApiKeys";
import EditFileDialog from "./EditFileDialog";
import EditBucket from "./EditBucket";
import CreateFolderDialog from "./CreateFolderDialog";
import DeleteFileDialog from "./DeleteFileDialog";

type fileData = {
    name: string;
    type: string;
    createdOnTime: string;
    createdOnDate: string;
    size: string;
    files: string;
    url: string;
}

const theme = createTheme({
    palette: {
        primary: {
            main: 'rgb(0,0,0)', // Set your desired color here
        },
    },
});

function tabProps(index: number) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
    return (
        <Box sx={{display: 'flex', alignItems: 'center'}}>
            <Box sx={{width: '100%', mr: 1}}>
                <ThemeProvider theme={theme}>
                    <LinearProgress variant="determinate" {...props} color="primary"/>
                </ThemeProvider>
            </Box>
            <Box sx={{minWidth: 35}}>
                <Typography variant="body2" color="black">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

const FileTable = () => {
    const [searchParams, setSearchParams] = useSearchParams()
    const {
        data,
        error,
        isLoading,
        mutate
    } = useSWR(url + `/api/v1/user/bucket/${searchParams.get("name")}?path=${searchParams.get("path")}`, get)
    const theme = useTheme()

    const [folderConfirm, setFolderConfirm] = useState<boolean>(false)

    const [deleteConfirm, setDeleteConfirm] = useState<boolean>(false)
    const [deleteFileName, setDeleteFileName] = useState("")
    const [deleteType, setDeleteType] = useState("")

    const [editFileName, setEditFileName] = useState<boolean>(false)
    const [oldFileName, setOldFileName] = useState("")

    const [index, setIndex] = useState<number>(0)

    const [fileUpload, setFileUpload] = useState<boolean>(false)
    const [uploadPercent, setUploadPercent] = useState(0)
    const [uploadFileName, setUploadFileName] = useState("")

    function folderClicked(name: string) {
        setSearchParams(params => {
            params.set("path", params.get("path") + "/" + name)
            return params
        })
    }

    if (isLoading) {
        return (
            <h1> Loading </h1>
        )
    }
    if (error) {
        return (
            <h1> Error </h1>
        )
    }

    if (data) {
        return (
            <>
                <EditFileDialog editFileName={editFileName} oldFileName={oldFileName} setEditFileName={setEditFileName} searchParams={searchParams} mutate={mutate}/>
                <CreateFolderDialog folderConfirm={folderConfirm} setFolderConfirm={setFolderConfirm} searchParams={searchParams} mutate={mutate}/>
                <DeleteFileDialog deleteConfirm={deleteConfirm} setDeleteConfirm={setDeleteConfirm} deleteFileName={deleteFileName} searchParams={searchParams} mutate={mutate} type={deleteType}/>
                <AppBar position="static" sx={{
                    backgroundColor: "rgb(7,7,7)"
                }}>
                    <Tabs
                        value={index}
                        onChange={(_e, val) => setIndex(val)}
                        indicatorColor="secondary"
                        textColor="inherit"
                        variant="fullWidth"
                        aria-label="full width tabs example"
                    >
                        <Tab label="Files" {...tabProps(0)} />
                        <Tab label="Edit Bucket" {...tabProps(1)} />
                        <Tab label="API Keys" {...tabProps(2)} />
                    </Tabs>
                </AppBar>
                <SwipeableViews
                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                    index={index}
                    onChangeIndex={setIndex}
                >
                    <TabPanel index={0} value={index} dir={theme.direction}>
                        <div style={{
                            marginTop: "10px"
                        }}>
                            <div style={{float: "right"}}>
                                <Button
                                    style={{marginRight: "8px"}}
                                    component="label"
                                    variant="contained"
                                    startIcon={<Icon path={mdiPlus} size={1}/>}
                                    onClick={() => setFolderConfirm(true)}
                                >
                                    Create Folder
                                </Button>
                                <Button
                                    style={{marginRight: "8px"}}
                                    component="label"
                                    variant="contained"
                                    startIcon={!fileUpload ? <Icon path={mdiUpload} size={1}/> : <></>}
                                >
                                    {fileUpload && (
                                        <div style={{display: "flex", flexDirection: "column"}}>
                                            <span>{uploadFileName}</span>
                                            <LinearProgressWithLabel value={uploadPercent}/>
                                        </div>)}
                                    {!fileUpload && (<input id="file-selector" type="file" style={{display: "none"}}
                                                           onChange={() => {
                                                               const fileSelector = document.getElementById("file-selector") as HTMLInputElement
                                                               if (!fileSelector.files && !fileSelector.files[0]) return

                                                               const file = fileSelector.files[0]
                                                               //if (file.size / 1024 / 1024 > 100) return

                                                               const formData = new FormData()
                                                               formData.append("bucketname", searchParams.get("name"))
                                                               formData.append("path", searchParams.get("path"))
                                                               formData.append("filename", file.name)
                                                               formData.append("file", file)

                                                               setUploadFileName(file.name)
                                                               setFileUpload(true)

                                                               post(url + "/api/v1/user/bucket/file/upload", formData, () => {
                                                                   setFileUpload(false)
                                                                   mutate()
                                                               }, (event) => setUploadPercent(Math.round((event.loaded * 100) / event.total)))
                                                           }}/>)}
                                    {!fileUpload && ("Upload file")}
                                </Button>
                            </div>
                            <TableContainer sx={{width: "100%"}}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell component="th" sx={{fontWeight: "bold"}}> Name </TableCell>
                                            <TableCell component="th" sx={{fontWeight: "bold"}}> Type </TableCell>
                                            <TableCell component="th" sx={{fontWeight: "bold"}}> Created </TableCell>
                                            <TableCell component="th" sx={{fontWeight: "bold"}}> Size </TableCell>
                                            <TableCell component="th" sx={{fontWeight: "bold"}}> Files </TableCell>
                                            <TableCell component="th" sx={{fontWeight: "bold"}}> Controls </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data.data.map((file: fileData) => (
                                            <TableRow
                                                key={file.name}
                                                sx={{
                                                    "& th": {
                                                        height: "50px",
                                                        textAlign: "start"
                                                    },
                                                    "& th:first-child span:hover": {
                                                        filter: "brightness(0.4)",
                                                        cursor: "pointer"
                                                    }
                                                }}>
                                                {file.type === "folder" ?
                                                    <TableCell component="th" scope="row" size="small">
                                                        <span
                                                            onClick={() => folderClicked(file.name)}>{file.name}</span>
                                                    </TableCell> :
                                                    <TableCell component="th" scope="row" size="small">
                                                        <span
                                                            onClick={() => window.open(file.url ?? "")}>{file.name}</span>
                                                    </TableCell>
                                                }
                                                <TableCell>{file.type}</TableCell>
                                                <TableCell>{file.createdOnTime}, {file.createdOnDate}</TableCell>
                                                <TableCell>{file.size}</TableCell>
                                                <TableCell>{file.files ?? ""}</TableCell>
                                                <TableCell>
                                                    {file.type === "file" && (<Tooltip title="Downlaod">
                                                        <IconButton size="small" onClick={() => {
                                                            window.open(url + `/api/v1/file/download/${file.url.substring(file.url.lastIndexOf("/") + 1)}`)
                                                        }}>
                                                            <Icon path={mdiDownload} size={1.2}/>
                                                        </IconButton>
                                                    </Tooltip>)}
                                                    <Tooltip title="Edit">
                                                        <IconButton size="small" onClick={() => {
                                                            setOldFileName(file.name)
                                                            setEditFileName(true)
                                                        }}>
                                                            <Icon path={mdiPencil} size={1.2}/>
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title="Delete">
                                                        <IconButton size="small" onClick={() => {
                                                            setDeleteFileName(file.name)
                                                            setDeleteType(file.type)
                                                            setDeleteConfirm(true)
                                                        }}>
                                                            <Icon path={mdiDelete} size={1.2}/>
                                                        </IconButton>
                                                    </Tooltip>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </TabPanel>
                    <TabPanel index={1} value={index}>
                        <EditBucket/>
                    </TabPanel>
                    <TabPanel index={2} value={index}>
                        <ApiKeys/>
                    </TabPanel>
                </SwipeableViews>
            </>
        )
    }
}

const FileView = () => {
    useEffect(() => {
        document.title = 'View Files';
    }, [])

    return (
        <div className="content">
            <div>
                <FileTable/>
            </div>
        </div>
    );
}

export default FileView;