import axios, {AxiosProgressEvent, AxiosResponse} from "axios";

export async function get(url: string,
                          callback?: (response: AxiosResponse<any, any>) => void,
                          uploadProgress?: (event: AxiosProgressEvent) => void,
                          downloadProgress?: (event: AxiosProgressEvent) => void) {

    const headers = {
        "Authorization": "Bearer " + localStorage.getItem("token")
    }

    const req = await axios.get(url, {
        headers,
        onUploadProgress: uploadProgress ? (progressEvent) => uploadProgress(progressEvent) : () => {},
        onDownloadProgress: downloadProgress ? (progressEvent) => downloadProgress(progressEvent) : () => {}
    })

    if (callback)
        callback(req)

    return req.data
}

export async function deleteRequest(url: string,
                                    data: {} | FormData,
                                    callback?: (response: AxiosResponse<any, any>) => void,
                                    uploadProgress?: (event: AxiosProgressEvent) => void,
                                    downloadProgress?: (event: AxiosProgressEvent) => void) {
    const headers = {
        "Authorization": "Bearer " + localStorage.getItem("token")
    }

    const req = await axios.delete(url, {
        headers,
        data,
        onUploadProgress: uploadProgress ? (progressEvent) => uploadProgress(progressEvent) : () => {},
        onDownloadProgress: downloadProgress ? (progressEvent) => downloadProgress(progressEvent) : () => {}
    })

    if (callback)
        callback(req)

    return req.data
}

export async function post(url: string,
                           data: {} | FormData,
                           callback?: (response: AxiosResponse<any, any>) => void,
                           uploadProgress?: (event: AxiosProgressEvent) => void,
                           downloadProgress?: (event: AxiosProgressEvent) => void) {

    const headers = {
        "Authorization": "Bearer " + localStorage.getItem("token")
    }

    const req = await axios.post(url, data, {
        headers,
        validateStatus: (status) => status >= 200 && status < 500,
        onUploadProgress: uploadProgress ? (progressEvent) => uploadProgress(progressEvent) : () => {},
        onDownloadProgress: downloadProgress ? (progressEvent) => downloadProgress(progressEvent) : () => {}
    })
    if (callback)
        callback(req)

    return req.data
}